$ix_bg: #FFF;
$ix_bg_dark: #E8F0F4;


$ix_fg: #475F72;
$ix_fg_light: lighten($ix_fg,15%);
$ix_fg_dark: darken($ix_fg,15%);

$ix_chrome: #D0D7DD;
$ix_chrome_light: lighten($ix_chrome,10%);
$ix_chrome_dark: darken($ix_chrome,10%);

$ix_invert_bg: #22303A;
$ix_invert_bg_light: lighten($ix_invert_bg,10%);
$ix_invert_bg_dark: darken($ix_invert_bg,10%);

$ix_invert_fg: #FAFAFA;
$ix_invert_fg_dark: $ix_chrome;

$ix_invert_chrome: #637787;
$ix_invert_chrome_light: lighten($ix_invert_chrome,10%);
$ix_invert_chrome_dark: darken($ix_invert_chrome,10%);

$ix_orange: #F8510F;
$ix_orange_light: lighten($ix_orange,10%);
$ix_orange_lighter: lighten($ix_orange,20%);
$ix_orange_dark: darken($ix_orange,10%);
$ix_orange_darker: darken($ix_orange,20%);

$ix_blue: #00C0FA;
$ix_blue_light: lighten($ix_blue,10%);
$ix_blue_lighter: lighten($ix_blue,20%);
$ix_blue_dark: darken($ix_blue,10%);
$ix_blue_darker: darken($ix_blue,20%);

$ix_green: #28E398;
$ix_green_light: lighten($ix_green,20%);
$ix_green_dark: darken($ix_green,10%);

$ix_yellow: #FED800;
$ix_yellow_light: #FCED96;
$ix_yellow_dark: #EFA50D;

$ix_steelgray: #496070;
$ix_steelgray_light: lighten($ix_steelgray, 10%);
$ix_steelgray_lighter: lighten($ix_steelgray, 20%);
$ix_steelgray_dark: darken($ix_steelgray, 10%);
$ix_steelgray_darker: darken($ix_steelgray, 20%);

// The blacks and whites are only gradiated by 5% increments because the
// the changes are much more noticeable.
$ix_black: #171e23;
$ix_black_dark: darken($ix_black, 5%);
$ix_black_darker: darken($ix_black, 10%);
$ix_black_light: lighten($ix_black, 5%);
$ix_black_lighter: lighten($ix_black, 10%);

$ix_white: #f2f6f9;
$ix_white_dark: darken($ix_white, 5%);
$ix_white_darker: darken($ix_white, 10%);
$ix_white_light: lighten($ix_white, 5%);
$ix_white_lighter: lighten($ix_white, 10%);

.ixbg { background:$ix_bg; }
.ixbg_dark { background:$ix_bg_dark; }

.ix_fg { background:$ix_fg; }
.ix_fg_light { background:$ix_fg_light; }
.ix_fg_dark { background:$ix_fg_dark; }

.ix_chrome { background:$ix_chrome; }
.ix_chrome_light { background:$ix_chrome_light; }
.ix_chrome_dark { background:$ix_chrome_dark; }

.ix_invert_bg { background:$ix_invert_bg; }
.ix_invert_bg_light { background:$ix_invert_bg_light; }
.ix_invert_bg_dark { background:$ix_invert_bg_dark; }

.ix_invert_fg { background:$ix_invert_fg; }
.ix_invert_fg_dark { background:$ix_invert_fg_dark; }

/* Used for borders, dividers, etc. */
.ix_invert_chrome { background:$ix_invert_chrome; }
.ix_invert_chrome_light { background:$ix_invert_chrome_light; }
.ix_invert_chrome_dark { background:$ix_invert_chrome_dark; }

/*--- Accent Colors ---*/

/* Primary accent color (orange) */
.ix_orange { background:$ix_orange; }
.ix_orange_light { background:$ix_orange_light; }
.ix_orange_dark { background:$ix_orange_dark; }
.ix_orange_lighter { background:$ix_orange_lighter; }
.ix_orange_darker { background:$ix_orange_darker; }

/* Secondary accent color (blue) */
.ix_blue { background:$ix_blue; }
.ix_blue_light { background:$ix_blue_light; }
.ix_blue_dark { background:$ix_blue_dark; }
.ix_blue_lighter { background:$ix_blue_lighter; }
.ix_blue_darker { background:$ix_blue_darker; }

/* Tertiary accent colors */
.ix_green { background:$ix_green; }
.ix_green_light { background:$ix_green_light; }
.ix_green_dark { background:$ix_green_dark; }
.ix_yellow { background:$ix_yellow; }
.ix_yellow_light { background:$ix_yellow_light; }
.ix_yellow_dark { background:$ix_yellow_dark; }


