@import 'bourbon';

.param-wrap {
    background-color: $ix_white_lighter;;
     .select2-container.select2-container-active {
            border:solid 1px darken($ix_white_darker,20%);
        }
    .select2-container {
        padding-right: 24px;
        background: $ix_white_lighter;
        box-shadow: none;

        .select2-choice {
            display: block;
            padding: 0;
            overflow: hidden;
            position: relative;
            border: none;
            height: auto;
            white-space: nowrap;
            line-height: 1.4;
            color: darken($ix_white_darker, 30%);
            text-decoration: none;
            border-radius: 0;
            background-clip: padding-box;
            @include user-select(none);
            user-select: none;
            background: transparent;
            font-family: $font_default;
            box-shadow: none;

            .select2-arrow {
                display: none;
                box-shadow: none;
            }
        }

        .select2-choice{
            color: darken($ix_white_darker, 10%);
        }

        .select2-choice > .select2-chosen {
            margin: 0;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            float: none;
            width: auto;
            background: transparent;
            box-shadow: none;
            color: darken($ix_white_darker, 30%);
        }
    }
}

.select2-drop {
    color: darken($ix_white_darker, 30%);
    box-shadow: none;
    -webkit-box-shadow: none;
}

.select2-drop.select2-drop-active {
    color: darken($ix_white_darker, 30%);
    border: solid 1px darken($ix_white_darker,20%);
    background-color: $ix_white_lighter;;
    border-top: none;
    box-shadow: 0px 1px 2px rgba(darken($ix_white_darker, 20%), .5);
}

.select2-search {
    background-color: $ix_white_lighter;;
    color: darken($ix_white_darker, 30%);
    border: 0;
}

.select2-search-choice-close {
    background: transparent;
    display: none;
}

.select2-container.select2-allowclear {
    .select2-choice {
        .select2-search-choice-close {
            display: none;
        }
    }
}

.select2-search input {
    width: 100%;
    min-height: 26px;
    padding: 4px 20px 4px 5px;
    margin: 0;
    outline: 0;
    font-family: $font_default;
    color: darken($ix_white_darker, 20%);
    font-size: 120%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent !important;
    margin-bottom: 4px;
    border: solid 1px darken($ix_white_dark, 5%);
}

.select2-search input:focus {
    border: solid 1px darken($ix_white_darker, 5%);
}

.select2-results {
    background-color: $ix_white_lighter;;
}

.select2-results .select2-highlighted {
    background-color: $ix_blue;
    color: $ix_white_lighter;;
}