@import 'bower_components/normalize.css/normalize';
@import 'bower_components/site-shared/dist/colors';
@import 'bourbon';
@import 'colors';
@import 'json';
@import 'small-file-uploader';

@mixin clearfix() {
    content: '';
    display: block;
    clear: both;
}

%group {
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

@mixin truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin subpixaa() {
    -webkit-font-smoothing: subpixel-antialiased;
}

$font_default:"Open Sans",
"Helvetica Neue",
Arial,
sans;
$font_code:monospace;
* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

a{
    outline: none;
}

body {
    color: #333;
    width: 100%;
    height: 100%;
    background: #fafafa;
    text-rendering: optimizeLegibility;
    font-family: $font_default;
    font-size: 100%;
    margin: 0;
    border: none;
    padding: 0;
}

h3 {
    color: $inactiveLight;
    font-weight: 600;
}

hr {
    border: none;
    border-top: solid 1px $ix_white_dark;
    margin: 40px 0;
}
video{
    max-width: 100%;
}

section {
    width: 100%;
    h1 {
        color: $inactiveLight;
        font-weight: 600;
        text-align: center;
        font-size: 150%;
    }
    h2 {
        color: $inactiveLight;
        font-size: 120%;
        font-weight: 600;
        text-align: center;
    }
    p {
        color: darken($ix_white_darker, 30%);
        padding-left: .5em;
        padding-right: .5em;
        max-width: 34em;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.4;
        font-size: 100%;
    }
    p a:hover {
        border-bottom: solid 1px $ix_blue_light;
    }
}

a {
    text-decoration: none;
    color: $ix_blue;
}

section.imgix {
    text-align: left;
}

section.imgix-create-header {
    text-align: center;
    background-color: $ix_black_light;
    padding: 40px;
    .logo {
        margin: 0;
        display: inline-block;
        padding: 0;
        vertical-align: middle;
    }
    a {
        text-decoration: none;
    }
    a span.logo-text {
        display: inline-block;
        margin-top: 0em;
        margin-left: .25em;
        font-size: 195%;
        font-weight: 800;
        vertical-align: middle;
    }
}

@import "image_uploader";

section.imgix-create {
    padding: 40px 20px 0 20px;
    text-align: center;
    .create {
        text-align: center;
        padding: 1em 0;
        input[type="text"], input[type="number"] {
            display: block;
            float: left;
            font-size: 120%;
            width: 79%;
            padding-right: 1em;
            vertical-align: middle;
            margin: 0 0 0 0;
        }
        button {
            display: block;
            float: left;
            margin-bottom: 0;
            margin-left: 1%;
            font-size: 100%;
            width: 20%;
            padding-top: 1em;
            padding-bottom: 1em;
            background-color: $ix_blue;
            @include user-select(none);
            cursor: pointer;

            &[disabled] {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
        button:hover {
            background-color: $ix_blue_light;
        }
        input {
            -webkit-appearance: none;
            border-radius: 0 !important;
        }
        input[type="text"],
        input[type="number"] {
            max-width: 780px;
            @include transition(background-color 120ms ease-out, border-color 120ms ease-out);
        }
        input[type="text"]:focus,
        input[type="number"]:focus {
            color: darken($ix_white_darker, 30%);
            font-weight: 400;
        }
        ::-webkit-input-placeholder {
            color: $ix_white_dark;
            font-weight: 400;
        }
        :-moz-placeholder {
            /* Firefox 18- */

            color: $ix_white_dark;
        }
        ::-moz-placeholder {
            /* Firefox 19+ */

            color: $ix_white_dark;
        }
        :-ms-input-placeholder {
            color: $ix_white_dark;
        }
    }
    .create:after {
        @include clearfix();
    }
}

::-webkit-input-placeholder {
    color: darken($ix_white_darker, 10%);
}

:-moz-placeholder {
    /* Firefox 18- */

    color: darken($ix_white_darker, 10%);
}

::-moz-placeholder {
    /* Firefox 19+ */

    color: darken($ix_white_darker, 10%);
}

:-ms-input-placeholder {
    color: darken($ix_white_darker, 10%);
}

section.imgix-footer {
    padding-top: 0px;
    padding-bottom: 20px;
    .imgix-row {
        text-align: center;
    }
    img {
        vertical-align: top;
    }
    p.copyright {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        margin-top: .3em;
        margin-left: 1em;
        line-height: 1.5;
        font-size: 90%;
        color: $inactiveLight;
    }
}

section.imgix-info {
    background-color: $ix_black_light;
    margin-bottom: 0;
    color: $ix_white;
    padding: 20px;
    text-align: left;
}

section.imgix-image {
    background-color: $ix_black;
    padding: 2em;
    position: relative;
}

section.imgix-examples,
section.imgix {
    .imgix-row {
        @include clearfix();
        position: relative;
        width: 100%;
        max-width: 960px;
        padding-left: 1em;
        padding-right: 1em;
    }
    h2 {
        color: $inactiveLight;
        font-weight: 400;
        text-indent: .5%;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        font-size: 150%;
    }
    .wrapper {
        float: left;
        width: 25%;
        padding: .5% .5% 0 .5%;
    }

    a.example {
        @include transition(opacity 120ms ease-out);
        display: block; 
        text-align: center;
        text-decoration: none;
        line-height: 1.3;
        color: $inactiveLight;
        img {
            width: 100%;
            max-width: 100%;
            border-radius: 4px;
        }
        p {
            color: darken($ix_white_darker, 30%);
            text-align: center;
            margin-top: .5em;
            font-size: 100%;
            padding-left: 1.5em;
            padding-right: 1.5em;
        }
    }
    a.example:hover {
        @include transition(opacity 120ms ease-out);
        opacity: .75;
    }
    @media (max-width: 760px) {
        .wrapper {
            width: 50%;
        }
        .imgix-row > div:nth-child(2n+1) {
            a.example {
                @include clearfix();
            }
        }
    }
}

section.imgix {
    h2 {
        padding-bottom: 0;
    }

    .imgix-ctas {
        display: flex;
        justify-content: center;

        margin: 40px auto 30px;

        .button {
            margin: 0;
            min-width: 150px;
            text-align: center;
            padding-top: 1em;
            padding-bottom: 1em;

            @media (max-width: 768px) {
                padding-top: 0.6em;
                padding-bottom: 0.6em;
            }

        }

        .button + .button {
            margin-left: 20px;
        }


        @media (max-width: 480px) {
            flex-wrap: wrap;

            .button {
                flex: 0 0 auto;
                width: 100%;
                max-width: 34em;
            }

            .button + .button {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}

input {
    font-family: $font_default;
}

input[type="text"]::-moz-selection,
input[type="number"]::-moz-selection,
textarea::-moz-selection {
    background-color: rgba($inactiveLight, .2);
}

input[type="text"]::selection,
input[type="number"]::selection,
textarea::selection,
{
    background-color: rgba($ix_white_darker, .8);
    color: $inactiveLight;
}

input[type="text"],
input[type="number"],
.select2-container {
    color: darken($ix_white_dark, 30%);
    border: solid 1px $ix_white_dark;
}

%focused-state {
    border: solid 1px darken($ix_white_darker, 20%);
}

input[type="text"]:focus,
input[type="number"]:focus {
    @extend %focused-state;
    color: $inactiveLight;
}

header {
    background-color: $ix_black_light;
    padding: .5em;
}

img {
    max-width: 100%;
}

pre {
    background-color: #3f3f3f;
}

.imgix-row.buttons {
    text-align: center;
    padding: 20px;
    @include user-select(none);
}

a.button,
button {
    @include user-select(none);
    margin-bottom: .5em;
    border-radius: 2px;
    line-height: 1;
    padding: .6em .8em;
    color: #fff;
    font-size: 100%;
    text-decoration: none;
    background-color: $ix_white_darker;
    border: none;
    cursor: pointer;
    outline: none;
    @include transition(background-color 120ms ease-out, border-color 120ms ease-out);
}

a.signup,
a.highlighted {
    background-color: $ix_orange;
    margin: 0 auto;
    display: inline-block;
    font-size: 100%;
    border: solid 1px transparent;

    &:active {
        background-color: $ix_orange_dark;
        border: solid 1px $ix_orange_dark;
    }

    &:focus {
        background-color: $ix_orange_dark;
        border: solid 1px $ix_orange;
    }

    &:hover {
        background-color: $ix_orange_light;
        border: solid 1px $ix_orange_light;
    }
}

a.request, a.normal{
    background-color: $ix_blue;
    margin: 0 auto;
    display: inline-block;
    font-size: 100%;
    border: solid 1px transparent;

    &:active {
        background-color: $ix_blue_darker;
        border: solid 1px $ix_blue_darker;
    }

    &:focus {
        background-color: $ix_blue_light;
        border: solid 1px $ix_blue;
    }

    &:hover {
        background-color: $ix_blue_light;
        border: solid 1px $ix_blue_light;
    }
}

.button.new,
.button.api {
    border: none;
    display: block;
    padding: 1em;
    width: 40%;
    background-color: $ix_blue;
    border: solid 1px $ix_blue;
    text-align: center;
    font-size: 100%;
    margin: 1.5em auto;
}

.button.api {
    margin-right: 2%;
}

.button.new:active {
    background-color: $ix_blue_darker;
    border: solid 1px $ix_blue_darker;
}

.button.new:focus {
    background-color: $ix_blue_light;
    border: solid 1px $ix_blue;
}

.button.new:hover {
    background-color: $ix_blue;
    border: solid 1px $ix_blue;
}

.button.api {
    background-color: darken($ix_white_darker, 20%);
    border: solid 1px darken($ix_white_darker, 20%);
}

.button.api:active {
    background-color: darken($ix_white_darker, 30%);
    border: solid 1px darken($ix_white_darker, 30%);
}

.button.api:hover {
    background-color: darken($ix_white_darker, 10%);
    border: solid 1px darken($ix_white_darker, 10%);
}

.button.remove {
    background-color: $ix_white_dark;
    border: solid 1px $ix_white_dark;
    position: absolute;
    top: 0;
    font-size: 140%;
    margin-top: 9px;
    right: 10px;
    padding: 14px 7px;
    line-height: 0;
}

.button.remove:hover,
.button.remove:active {
    background-color: $ix_orange;
    border: solid 1px $ix_orange;
}

.button.remove:focus {
    background-color: $ix_orange_light;
    border: solid 1px $ix_orange;
}

.button.build {
    background-color: $ix_blue;
    border: solid 1px $ix_blue;
    position: absolute;
    font-size: 140%;
    margin-top: 9px;
    right: 42px;
    padding: 14px 7px;
    line-height: 0;
}

.button.build:hover,
.button.build:active {
    background-color: $ix_blue_light;
    border: solid 1px $ix_blue_light;
}

.button.build:focus {
    background-color: $ix_blue_light;
    border: solid 1px $ix_blue;
}

@media(max-width:768px) {
    section.imgix-create {
        padding: 20px;
        margin-bottom: 20px;
        .imgix-row .create {
            input[type="text"], input[type="number"], .select2-container {
                font-size: 100%;
            }
            button {
                font-size: 80%
            }
        }
    }
    button {
        font-size: 100%;
    }
}

@media(max-width: 480px) {
    section.imgix-create {
        padding: 20px 20px 0 20px;
    }
}

section.imgix-constrain {
    font-size: 80%;
    background-color: $inactive;
    color: $ix_white;
    text-align: center;
    padding: 1em;
    line-height: 1.4;
    @include subpixaa();
    a {
        color: $ix_blue;
        text-decoration: none;
        @include subpixaa();
    }
}

section.imgix-create {
    p {
        text-align: left;
    }
}

section.imgix-embedded,
section.imgix-popup {
    .imgix-row{
        width:100%;
    }
    font-size: 100%;
    background-color: $ix_black_light;
    color: $ix_white;
    text-align: left;
    padding: 1em;
    line-height: 2.4;
    @include subpixaa();
    a {
        color: $ix_white;
        text-decoration: none;
        @include subpixaa();
    }
    button,
    button:active {
        font-size: 90%;
        background-color: $ix_blue;
        margin-bottom: 0;
        display: inline-block;
    }
    button:hover {
        background-color: $ix_blue_light;
    }
    img {
        vertical-align: middle;
        margin-right: 12px;
    }
    .composite {
        vertical-align: middle;
    }
    .logo {
        display: inline-block;
        float: none;
        vertical-align: middle;
    }
}

section.imgix-embedded {
    top:0;
    right:0;
    width:auto;
    min-width: 0;
    position: fixed;
    z-index: 99999;
    background-color: transparent;
      .imgix-row{
        width:auto;
    }
}

section.imgix-info:after {
    @include clearfix();
}

section.imgix-params {
    margin-top: 2em;
    .imgix-row.new-input-row {
        animation-name: fadein;
        animation-duration: .420s;
        animation-timing-function: ease-out;
        position: relative;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
        top: -12px;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

.imgix-wrap {
    position: relative;
    width: 100%;
    max-height: 400px;

    // Taller than 1080px
    @media(min-height: 1080px) {
        max-height: 600px;
    }

    // Between 721px and 1080px tall
    @media(max-height: 1080px) {
        max-height: 450px;
    }

    // Between 641px and 720px tall
    @media(max-height: 720px) {
        max-height: 360px;
    }

    // Between 541px and 640px tall
    @media(max-height: 640px) {
        max-height: 280px;
    }
    // Between 481px and 540px tall
    @media(max-height: 540px) {
        max-height: 220px;
    }

    // Between 401px and 480px tall
    @media(max-height: 480px) {
        max-height: 200px;
    }

    // Between 321px and 400px tall
    @media(max-height: 400px) {
        max-height: 160px;
    }

    // Between 0 and 320px tall
    @media(max-height: 320px) {
        max-height: 120px;
    }

    img {
        display: block;
        margin: auto;
    }
}

.imgix-row {
    position: relative;
    margin: 0 auto;
    width: 100%;
    input[type="text"], input[type="number"], .select2-container {
        display: block;
        float: left;
        margin-bottom: .5em;
    }
}

.imgix-row:after {
    @include clearfix();
}

.select-wrapper {
    background-color: $ix_white_lighter;;
    float: left;
    width: 49%;
    position: relative;
    &:before {
        display: block;
        content: ' ';
        position: absolute;
        right: 0;
        margin-right: 38px;
        border-left: solid 1px $ix_white_dark;
        height: 100%;
    }
    &:after {
        display: block;
        content: ' ';
        position: absolute;
        right: 0;
        margin-right: 12px;
        margin-top: 21px;
        border-top: solid 8px $ix_white_darker;
        border-right: solid 8px transparent;
        border-bottom: solid 8px transparent;
        border-left: solid 8px transparent;
    }
    &:hover {
        &:after {
            border-top-color: darken($ix_white_darker, 10%);
            @include transition(color 120ms ease-out, border-color 120ms ease-out);
        }
        &:before {
            border-left-color: darken($ix_white_darker, 10%);
            @include transition(color 120ms ease-out, border-color 120ms ease-out);
        }
        select {
            border-color: darken($ix_white_darker, 10%);
            @include transition(color 120ms ease-out, border-color 120ms ease-out);
        }
    }
}

.imgix-url-row {
    margin: 0 auto 1em auto;
    input[type="text"]:disabled, input[type="number"]:disabled {
        color: darken($ix_white_darker, 20%);
        border: solid 1px $ix_white_dark;
        cursor: not-allowed;
        margin-right: 2%;
        padding-right: 1em;
        width: 49%;
        margin-bottom: 0;
    }
    .select-wrapper {
        background-color: $ix_white_lighter;;
        float: left;
        width: 49%;
        position: relative;
        &:before {
            display: block;
            content: ' ';
            position: absolute;
            right: 0;
            margin-right: 38px;
            border-left: solid 1px $ix_white_dark;
            height: 100%;
        }
        &:after {
            display: block;
            content: ' ';
            position: absolute;
            right: 0;
            margin-right: 12px;
            margin-top: 21px;
            border-top: solid 8px $ix_white_darker;
            border-right: solid 8px transparent;
            border-bottom: solid 8px transparent;
            border-left: solid 8px transparent;
        }
        &:hover {
            &:after {
                border-top-color: darken($ix_white_darker, 10%);
                @include transition(color 120ms ease-out, border-color 120ms ease-out);
            }
            &:before {
                border-left-color: darken($ix_white_darker, 10%);
                @include transition(color 120ms ease-out, border-color 120ms ease-out);
            }
            select {
                border-color: darken($ix_white_darker, 10%);
                @include transition(color 120ms ease-out, border-color 120ms ease-out);
            }
        }
    }
    select {
        cursor: pointer;
        outline: none;
        float: left;
        font-size: 120%;
        padding: .5em;
        box-sizing: border-box;
        border: solid 1px $ix_white_dark;
        font-family: $font_default;
        font-weight: 600;
        background-color: transparent;
        outline: none;
        border-radius: 0;
        @include appearance(none);
        width: 100%;
        position: relative;
        z-index: 2;
        color: darken($ix_white_darker, 30%);
        margin: 0;
        padding-right: 40px;
        @include truncate();
        text-overflow: ellipsis;
    }
}

.imgix-about{
    padding-top:20px;
}

.imgix-share {
    margin: 0;
    padding-top: 20px;
    h3 {
        float: left;
        font-size: 90%;
        display: block;
        background-color: $ix_white;
        border-left: solid 1px $ix_white_darker;
        border-top: solid 1px $ix_white_darker;
        border-right: solid 1px $ix_white_darker;
        border-radius: 4px 4px 0 0;
        padding: 1em 20px .8em 20px;
        margin: 0 0 -1px 0;
        color: darken($ix_white_darker, 30%);
    }
    textarea.copy-link {
        font-family: $font_code;
        width: 100%;
        padding: 20px;
        border: solid 1px $ix_white_darker;
        color: lighten($inactiveLight, 5%);
        background-color: $ix_white;
        display: block;
        word-wrap: break-word;
        word-break: break-all;
        line-height: 1.4;
        border-radius: 2px;
        outline: none;
        resize: vertical;
        font-size: 130%;
        min-height: 120px;
        clear: both;
    }
    .imgix-row.share {
        button, a.button {
            font-size: 90%;
            margin: 0;
            float: right;
            vertical-align: bottom;
            margin-top: .35em;
            background-color: darken($ix_white_darker, 10%);
        }
        button {
            margin-right: .5em;
        }
        a.button:last-child,
        button:last-child {
            margin-right: 0;
        }
        .button.share:hover {
            background-color: $ix_white_darker;
        }
        .button.share:active {
            background-color: darken($ix_white_darker, 20%);
        }
    }
}

.imgix-info {
    .imgix-row {
        text-align: center;
    }
    .info-block {
        text-align: left;
        vertical-align: middle;
        display: inline-block;
        border-left: solid 1px $ix_black_lighter;
        font-size: 80%;
        padding: 1em 2em;
        @include subpixaa();
        @media(max-width: 767px) {
            padding: 1em;
        }
        .button {
            font-size: 90%;
            @include subpixaa();
            display: inline-block;
            width: 100%;
            text-align: center;
            background-color: $inactive;
        }
        .button:hover {
            background-color: $inactiveLight;
        }
        .button.action:last-child {
            margin-bottom: 0;
        }
    }
    .info-block:first-child {
        border-left: none;
        padding-left: 0;
    }
    .info-block:last-child {
        padding-right: 0;
    }
    .info-item {
        display: block;
        color: $ix_white_dark;
        @include subpixaa();
    }
    .info-data {
        display: block;
        color: $ix_blue;
        @include subpixaa();
        code {
            @include subpixaa();
        }
    }

    .info-block_links {
        .button:first-child {
            background-color: $ix_orange;

            &:hover {
                background-color: $ix_orange_light;
            }
        }

        .button:nth-child(2) {
            background-color: $ix_blue;

            &:hover {
                background-color: $ix_blue_light;
            }
        }
    }
}

.sandbox-logo{
    display: block;
    margin:0 auto;
}
.sandbox-logo-footer{
    display: inline;
    vertical-align: middle;
    width:300px;
    padding-bottom:10px;
}


.logo {
    display: block;
    border-left: none;
    img {
        width: 80px;
        height: 30px;
        margin-top: -7px;
        @media(min-width: 600px) {
            width: 120px;
            height: 45px;
        }
    }
}

.logo-text {
    font-size: 180%;
    text-indent: .08em;
    font-weight: 900;
    color: $ix_blue_light;
    margin: 0;
    display: block;
    margin-top: -.2em;
    text-indent: .01em;
    text-transform: uppercase;
    @media(max-width: 600px) {
        font-size: 125%;
    }
}

.set {
    display: block;
    float: left;
    padding: .8em 0;
    margin: 0 1%;
    color: darken($ix_white_darker, 20%);
    width: 4%;
    font-weight: 700;
    text-align: center;
}

input,
textarea {
    @include appearance(none);
}

input[type="text"],
input[type="number"],
.select2-container {
    outline: none;
    font-size: 120%;
    padding: .5em;
    box-sizing: border-box;
    margin-bottom: .5em;
    &.invalid {
        border-color: $ix_orange_light;
        color: $ix_orange;
        @include transition(color 120ms ease-out, border-color 120ms ease-out);
        &:focus {
            @extend %focused-state;
            border-color: $ix_orange_light;
            color: $ix_orange;
        }
    }
    &.deprecated {
        border-color: saturate(lighten($ix_yellow_dark, 15%), 10%);
        color: $ix_yellow_dark;
        &:focus {
            @extend %focused-state;
        }
    }
    @include subpixaa();
    @include truncate();
}

.param-wrap {
    display: block;
    position: relative;
    width: 20%;
    margin-right: 1%;
    input[type="text"], input[type="number"], .select2-container {
        width: 100%;
        padding-right: 40px;
    }
}

input[type="text"].param-value,
input[type="number"].param-value {
    padding-right: 77px;
    width: 74%;
    margin-left: 0%;
}

.tooltip-hover {
    position: absolute;
    display: block;
    background-color: rgba($inactive, .98);
    color: $ix_white_lighter;;
    padding: 1em;
    left: 150%;
    bottom: 999999px;
    border-radius: 4px;
    font-weight: 600;
    width: 16em;
    opacity: 0;
    z-index: 99999;
    @include transition(opacity 50ms ease-in, left 50ms ease-in, bottom 1ms ease-out 50ms);
    span.title {
        font-weight: 800;
        display: inline-block;
        padding-bottom: .25em;
    }
}

.tooltip:hover > .tooltip-hover {
    opacity: 1;
    bottom: -50px;
    left: 120%;
    @include transition(opacity 100ms ease-out 150ms, left 100ms ease-out 150ms, bottom 1ms ease-out 150ms);
}

.tooltip {
    position: absolute;
    right: 6px;
    vertical-align: middle;
    margin-top: 5px;
    font-weight: 600;
    cursor: pointer;
    color: $ix_white_darker;
    padding: .5em .8em;
    color: darken($ix_white_darker, 10%);
    outline: none;
}

.tooltip:hover .tooltip-hover a {
    color: $ix_blue;
}

.tooltip:hover {
    color: darken($ix_white_darker, 20%);
    outline: none;
}

.tooltip:hover .tooltip-hover a:hover {
    color: $ix_blue_light;
}

.tooltip-hover:hover {
    cursor: default;
}

.tooltip-hover:before {
    content: '';
    display: block;
    position: absolute;
    border-right: solid 8px rgba($inactive, .98);
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
    border-left: solid 8px transparent;
    left: -16px;
    bottom: 60px;
}

.tooltip,
.tooltip-hover {
    @include appearance(none);
}

.transparent-grid {
    background: $ix_black;
    background-image: -moz-linear-gradient(45deg, darken($ix_black, 10%) 25%, transparent 25%), -moz-linear-gradient(-45deg, darken($ix_black, 10%) 25%, transparent 25%), -moz-linear-gradient(45deg, transparent 75%, darken($ix_black, 10%) 75%), -moz-linear-gradient(-45deg, transparent 75%, darken($ix_black, 10%) 75%);
    background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, darken($ix_black, 10%)), color-stop(0.25, rgba(0, 0, 0, 0))), -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, darken($ix_black, 10%)), color-stop(0.25, rgba(0, 0, 0, 0))), -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.75, rgba(0, 0, 0, 0)), color-stop(0.75, darken($ix_black, 10%))), -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.75, rgba(0, 0, 0, 0)), color-stop(0.75, darken($ix_black, 10%)));
    background-position: 0 0, 5px 0, 5px -5px, 0 5px;
    -webkit-background-size: 10px 10px;
    -moz-background-size: 10px 10px;
    background-size: 10px 10px;
}

.container {
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 4em;
    min-width: 280px;
}

section {
    padding-left: .5em;
    padding-right: .5em;
}

@media (min-width: 400px) {
    .imgix-row {
        width: 100%;
    }
}

@media (min-width: 550px) {
    .imgix-row {
        width: 530px;
    }
    section {
        padding-left: 0em;
        padding-right: 0em;
    }
}

@media (max-width: 640px) {
    .button.new,
    .button.api {
        width: 80%;
    }
}

@media (max-width: 900px) {
    .imgix-info {
        .imgix-row .info-block:first-child {
            text-align: center;
            padding-bottom: 10px !important;
        }
        .info-block:after {
            @include clearfix();
        }
        .imgix-row .info-block {
            display: block;
            float: none;
            max-width: 30em;
            margin: 0 auto;
            padding: 0;
            border: 0;
            text-align: center;
            margin-bottom: 8px;
        }
        .info-block .info-item {
            margin-top: 0;
            display: block;
            float: left;
            width: 25%;
            font-size: 80%;
        }
        .info-block:last-child {
            margin-top: -6px;
        }
        .info-data {
            display: block;
            float: left;
            padding: 0;
            width: 25%;
            margin: 0;
            margin-top: 1px;
            text-align: center;
        }
        .info-data:nth-child(3n) {
            border-right: solid 1px $inactiveLight;
            border-left: solid 1px $inactiveLight;
        }
        .button.action {
            //background-color: $ix_blue;
            font-size: 120%;
            width: 100%;
            display: inline-block;
            padding: 1em;
        }
        .logo {
            display: inline-block;
            img {
                width: 80px;
                height: 30px;
                vertical-align: middle;
            }
        }
        .logo-text {
            font-size: 120%;
            display: inline-block;
            vertical-align: middle;
            margin-top: -.5em;
            margin-left: .5em;
        }
    }
    .imgix-info > .imgix-row {
        width: 100%;
    }
    section.imgix-share {
        h3 {
            display: block;
            float: left;
            font-size: 100%;
            background-color: transparent;
            border: none;
            padding: 1em 0;
            text-align: center;
        }
        .imgix-row.share {
            button {
                display: none;
            }
            .button {
                margin-top: 12px;
                float: right;
                font-size: 80%;
                display: inline-block;
            }
        }
        .button {
            font-size: 80%;
        }
    }
}

@media (min-width: 900px) {
    .imgix-row {
        width: 760px;
    }
}

@media (min-width: 1000px) {
    .imgix-row {
        width: 760px;
    }
}

@media (max-width: 640px) {
    section{
        hr{
            margin-top:20px;
            margin-bottom:2emb0px;
        }
    }
    section.imgix-embedded, section.imgix-popup{
        .logo{
                display: none;
            }
    }
    section.imgix-create-header {
        #logo {
            display: inline-block;
            margin: 0;
            img {
                width: 80px;
                height: 30px;
                vertical-align: middle;
            }
        }
        a span.logo-text {
            font-size: 120%;
            display: inline-block;
            vertical-align: middle;
            margin-top: 0;
            margin-left: .5em;
        }
    }
    section.imgix-create {
        .imgix-row .create {

            input[type="text"], input[type="number"], .select2-container {
                width: 100%;
                display: block;
                float:none;
            }
            button {
                display: block;
                font-size: 80%;
                clear: both;
                width:50%;
                margin: 1em auto 0 auto;
                float:none;
            }
        }
    }
    .param-wrap {
        width: 34%;
    }
    input[type="text"].param-value {
        width: 60%;
    }
    .param-wrap {
        input[type="text"], .select2-container {
            padding-right: .5em;
        }
    }
    .tooltip {
        display: none;
    }
}

@media (max-width: 400px) {
    section.imgix-params {
        width: 100%;
        hr {
            display: none;
        }
    }
    input[type="text"].source-name-input {
        display: none;
    }
    .imgix-url-row select,
    .imgix-url-row .select-wrapper {
        width: 100%;
    }
}

@media (max-width: 360px) {
    .imgix-info .imgix-row .info-block {
        max-width: none;
    }
    section.imgix-info {
        font-size: 80%;
        padding: 10px;
        padding-top: 20px;
        .info-item {
            .button {
                padding: .8em;
            }
        }
        .info-data {
            margin-top: .4em;
        }
    }
    section.imgix-share {
        .imgix-row.share {
            h3 {
                font-size: 80%;
            }
            .button.share {
                font-size: 60%;
            }
        }
    }
}

@import 'loadingani';
@import 'dropdown';

// The Google Remarketing tag is quite the bad citizen
iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    margin-top: -13px;
    float: left;
}

@media (max-width: 760px) {
    .view-examples {
        .imgix-row > a.example:nth-child(3n) {
            display: none;
        }
    }
}
