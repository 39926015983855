.progress {
    background: $ix_chrome;
    height: 10px;
    bottom: -10px;
    left: 0px;

    .bar {
        background: $ix_blue;
        height: 100%;
        width: 2%;
        transition: width 240ms ease-out;
    }
}

.imgix-create .image-uploader {
    padding-top: 30px;
    @include clearfix();

    text-align: center;

    input[type="checkbox"] {
        width: 16px;
        height: 16px;
        position: relative;
        top: 2px;
        margin-right: 4px;
        cursor: pointer;
        background-image: url("/checkboxes.svg");
        background-size: cover;
        background-position: top center;
        outline: 0;

        &:checked {
            background-position: bottom center;
        }
    }

    .or {
        text-transform: uppercase;
        display: block;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        color: $neutral;
        width: 42px;
        margin: 0 auto 18px;

        &:before, &:after {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            background: $ix_white_dark;
            height: 1px;
            width: 36px;
        }

        &:before {
            right: -100%;
        }

        &:after {
            left: -100%;
        }
    }

    .upload-cta {
        float: none;
        display: block;
        margin: 0 auto;
        white-space: nowrap;
        width: auto;
    }

    .upload-pane {
        @extend %group;
        position: relative;

        h3 {
            margin-bottom: 12px;
        }

        .upload-note {
            text-align: center;
            margin-top: 3em;
            font-size: 0.8em;
        }

        .drop-zone {
            margin: 2em auto 0 auto;
            @include clearfix();
            width: 385px;
            height: 256px;
            background-image: url("/photo.svg");
            background-size: 50%;
            background-color: $ix_white_light;
            background-repeat: no-repeat;
            background-position: 50% 2em;
            position: relative;
            border: 1px solid $ix_white_dark;
            font-family: $font_default;

            &.has-image {
                background-size: cover;
                background-position: 50% top;
                background-clip: content-box;
                padding: 10px;
            }

            &.dragging {
                &:not(.has-image) {
                    background-image: none;
                }

                &:after {
                    content: "";
                    background-image: url("/upload-photo.svg");
                    background-size: 50%;
                    background-repeat: no-repeat;
                    background-position: center 2em;
                    width: 385px;
                    height: 256px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    background-color: rgba($ix_white_dark, 0.8);
                }
            }

            p {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                color: $inactiveLight;
                font-size: 18px;
                margin: 0;
                padding: 12px 9px 1.5em;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;

                a {
                    cursor: pointer;
                }
            }

            input[type="file"] {
                opacity: 0.001;
                display: block;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        .progress {
            width: 385px;
            max-width: 100%;
            position: absolute;
        }

        .upload-disclaimer {
            display: block;
            margin-top: 1em;
            color: $inactiveLight;
        }

        .upload-trigger-wrapper {
            margin-top: 1em;
            width: 100%;

            button {
                width: 385px;
                max-width: 100%;
                float: none;
                margin: 0 auto;
            }
        }

        @media (max-width: 899px) {
            .drop-zone, .upload-options {
                float: none;
                width: 100%;
            }

            .drop-zone {
                background-size: 200px;

                p {
                    max-width: none;
                }
            }
        }

        @media (max-width: 516px) {
            .upload-options {
                height: auto;
                &:after {
                    content: ".";
                    display: block;
                    height: 0;
                    clear: both;
                    visibility: hidden;
                }

                li {
                    float: none;

                    &:nth-child(even) {
                        padding-left: 0;
                    }
                }

                .upload-disclaimer {
                    display: block;
                    margin-top: 12px;
                }

                .upload-trigger-wrapper {
                    width: 50%;
                    margin: 0 auto;

                    .upload-disclaimer {
                        width: 100%;
                    }

                    button {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

            }
        }
    }
}
