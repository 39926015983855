.json,
.css {
    font-size:70%;

    pre::selection {
        background-color: rgba($inactiveDark,.3);
    }
    pre::-moz-selection {
        background-color: rgba($inactiveDark,.3);
    }
    pre {
        word-break:break-all;
        background-color: $inactiveDark;
        max-width: 960px;
        margin: 0 auto;
        font-size: 140%;
        border-radius: 4px;
        padding: 1em 1em 2em 1em;
        color: $ix_blue_darker;
        border:solid 1px $inactive;
    }
    .hljs-attribute {
        color: $ix_blue;
    }
    .hljs-value {
        color: $ix_blue_darker;
    }
    .hljs-string {
        color: $ix_green;
    }
    .hljs-number{
        color: $ix_orange;
    }
}

.css {
    pre {
        white-space: pre-line;
    }
}

.css-colortable{
    height:auto;
    max-width:1024px;
    padding:20px 0 0 0;
    text-align: center;
    .swatch{
        width: 4.5%;
        margin:0.2%;
        background-color: $inactive;
        padding-bottom:4.5%;
        display:inline-block;
        @media (max-width: 640px){
            width: 10%;
            padding-bottom:10%;
            margin:0.5%;
        }
    }
}