@import 'bower_components/site-shared/dist/colors';

.small-file-uploader {
  display: inline-block;
  width: 25%;
  float: left;
  padding: .5% .5% 0 .5%;
  cursor: pointer;

  .drop-zone {
    background-repeat: no-repeat;
    background-image: url("/photo.svg");
    background-position: center 1.5em;
    background-color: $ix_white;
    border: 1px solid $ix_white_darker;
    border-radius: 4px;
    position: relative;
    @include transition(background 150ms ease-out, border-color 150ms ease-out);

    &:hover {
      border: 1px solid $ix_blue;
    }

    &.dragging {
      border: 1px solid $ix_blue;
      background-color: fade-out($ix_blue_light, 0.9);
    }

    p {
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
      display: block;
      padding-bottom: 0;
      margin-bottom: 1em;

      span {
        font-weight: 700;
        color: $ix_blue;
      }
    }

    input[type="file"] {
      opacity: 0.001;
      display: block;
      width: 100%;
      height: 167px;
      cursor: pointer;
      padding: 0;
      margin: 0;
    }
  }
}

@media (max-width: 760px) {
  .small-file-uploader {
    width: 100%;
    padding-bottom: 30%;

    .drop-zone {
      background-size: 50% 50%;

      p {
        margin-bottom: 1.5em;
      }
    }
  }
}