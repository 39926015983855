$nubSize: 16px;
$loadBarWidth: ($nubSize * 3) + ($nubSize/2)*3;
.overlay {
    position: absolute;
    background-color: rgba($ix_black, .8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    @include transition(opacity 120ms ease-out 500ms,top 0ms 621ms ,bottom 0ms 621ms);
    &.kill {
        opacity:0;
        top:-999999px;
        bottom:999998px;
        @include transition(opacity 120ms ease-in, top 0ms 121ms, bottom 0ms 121ms);
    }
}

.loading-animation {
    width: $loadBarWidth;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -$loadBarWidth/2 - $nubSize/2;
    margin-top: - ($nubSize / 2);
    display:block;

}

.loading-animation .loading-nub {
    float: left;
    width: $nubSize;
    height: $nubSize;
    margin-left: $nubSize/2;
    border: 0;
    -webkit-border-radius: $nubSize/8;
    -moz-border-radius: $nubSize/8;
    border-radius: $nubSize/8;
    @include animation-duration(1.2s);
    @include animation-iteration-count(infinite);
    @include animation-name(loadinganimation);
    @include animation-direction(linear);
}

.loading-animation .nub1 {
    @include animation-delay(0.24s);
}

.loading-animation .nub2 {
    @include animation-delay(0.48s);
}

.loading-animation .nub3 {
    @include animation-delay(0.76s);
}

@include keyframes(loadinganimation) {
    50% {
        background-color: $ix_blue;
    }
}

